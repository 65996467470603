


import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import textChatThumbnail from '@/_modules/text-chats/components/text-chat-thumbnail/text-chat-thumbnail.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import { TGetTextChatsParams } from '@/_api/text-chats.api';

const chatStore = namespace('chatStore');

@Component({
  components: {
    textChatThumbnail,
    iconTextChats
  }
})
export default class TextChatsList extends Vue {

  @chatStore.Action('getTextChats') public getTextChats: (params: TGetTextChatsParams) => Promise<void>;
  @chatStore.Getter('textChats') public readonly chatList: TTextChat[];

  public isListLoading: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get expandedChatId(): number {
    return this.$route.params.textChatId ? parseInt(this.$route.params.textChatId, 10) : null;
  }

  @Watch('expandedChatId')
  public onExpandedChatIdChange(): void {
    this.scrollToExpandedChat();
  }

  @Watch('chatList')
  public onChatListChange(): void {
    this.scrollToExpandedChat();
  }

  private scrollToExpandedChat(): void {
    if (!this.expandedChatId) {
      return;
    }

    this.$nextTick(() => {
      const GRID_GAP = 18;
      const expandedChatIndex: number = this.chatListFiltered.length === 0 ? 0 : this.chatListFiltered.findIndex((chat) => chat.id === this.expandedChatId);
      const expandedChatListItem: Element = (this.$refs.chatListItems as Element[])[expandedChatIndex];
      if (!expandedChatListItem) {
        return;
      }
      const rectItem: DOMRect = expandedChatListItem.getBoundingClientRect();
      this.$emit('scrollTopUpdated', (rectItem.height + GRID_GAP) * expandedChatIndex);
    });
  }

  public get isChatListEmpty(): boolean {
    if (!this.chatListFiltered) {
      return true;
    }
    return this.chatListFiltered.length === 0;
  }

  public get chatListFiltered(): TTextChat[] {
    const visibleChats: TTextChat[] = this.chatList.filter((chat) => !chat.is_hidden);
    const sortedChats: TTextChat[] = [];
    const unsortedChats: TTextChat[] = [];
    visibleChats.forEach((chat) => {
      if (chat.sorting > 0) {
        sortedChats.push(chat);
      } else {
        unsortedChats.push(chat);
      }
    });
    return sortedChats
      .sort((a, b) => {
        if (a.sorting < b.sorting) {
          return -1;
        } else if (a.sorting > b.sorting) {
          return 1;
        }
        return 0;
      })
      .concat(unsortedChats);
  }

  public mounted(): void {
    this.getChatList();
  }

  private async getChatList(): Promise<void> {
    this.isListLoading = true;
    await this.getTextChats({ eventId: this.eventId });
    this.isListLoading = false;
  }

}
